import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Input from "antd/lib/input";
import codes from "./codes.json";
import DownIcon from "../../assets/images/arrow-down.svg";

interface StyledConatinerProps {
	hasError?: boolean;
}

const StyledConatiner = styled.div<StyledConatinerProps>`
	width: 100%;
	position: relative;
	display: flex;
	border: ${(props) =>
		props.hasError ? "1px solid #EF4A1E" : "1px solid #CED5DE"};
	border-radius: 6px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	overflow: hidden;
`;

const StyledInput = styled(Input)`
	min-width: 250px;
	height: 46px;
	border: none !important;
	border-radius: 0px !important;
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5px;
	padding: 5px !important;
	background-color: white;
	outline: 0;
	&:focus {
		box-shadow: none !important;
	}
`;

const StyledSelect = styled.select`
	opacity: 0;
	border: none;
	width: 90px;
	height: 40px;
	&:focus-visible {
		outline: none;
	}
`;

const StyledSelectContainer = styled.div`
	width: 90px;
	position: relative;
`;

const StyledMask = styled.div`
	position: absolute;
	top: 3px;
	width: 90px;
	height: 40px;
	display: flex;
	align-items: center;
`;

interface PhoneNumberInputProps {
	placeholder?: string;
	onChange(value: string): void;
	value?: string;
	hasError?: boolean;
}

const PhoneNumberInput = ({
	placeholder = "",
	onChange,
	value = "",
	hasError = false,
	...props
}: PhoneNumberInputProps) => {
	const [ext, setExt] = useState("Belgium");
	const [phone, setPhone] = useState(value ?? "");

	const onPhoneChange = useCallback(
		(ext: string, phone: string) => {
			onChange(
				codes.find(({ name }) => name === ext).phoneNumberPrefix + phone
			);
		},
		[onChange]
	);

	useEffect(() => {
		onPhoneChange(ext, phone);
	}, [phone, ext]);

	return (
		<StyledConatiner hasError={hasError}>
			<StyledSelectContainer>
				<StyledMask>
					<img
						style={{ borderRadius: 3, marginLeft: 15, marginRight: 8 }}
						src={codes.find(({ name }) => name === ext).flag.svg}
						height={15}
						width={21}
					/>
					<span id="county-code">
						{codes.find(({ name }) => name === ext).phoneNumberPrefix}
					</span>
					<img
						style={{ marginLeft: 4 }}
						src={DownIcon}
						height={10}
						width={10}
					/>
				</StyledMask>
				<StyledSelect
					id="dropdown.country-code"
					value={ext}
					onChange={({ target: { value } }) => {
						setExt(value);
					}}
					{...props}
				>
					{codes.map(({ name }, index) => {
						return (
							<option key={`${index}`} value={name}>
								{name}
							</option>
						);
					})}
				</StyledSelect>
			</StyledSelectContainer>
			<StyledInput
				id="input.phone-number"
				onChange={(e) => {
					e.preventDefault();
					setPhone(e.target.value);
				}}
				value={phone}
				className="input"
				maxLength={10}
				// minLength={8}
				placeholder={placeholder}
				{...props}
			/>
		</StyledConatiner>
	);
};

export default PhoneNumberInput;
