import React, { useState, useRef } from "react";
import cover from "../../assets/images/home-page/cover.png";
import coverImageEnLg from "../../assets/images/home-page/cover-img-en-lg.png";
import coverImageEnMd from "../../assets/images/home-page/cover-img-en-md.png";
import coverImageEnSm from "../../assets/images/home-page/cover-img-en-sm.png";
import section1 from "../../assets/images/home-page/section-1.png";
import section2 from "../../assets/images/home-page/section-2.png";
import section3 from "../../assets/images/home-page/section-3.png";
import section4 from "../../assets/images/home-page/section-4.png";
import section5 from "../../assets/images/home-page/section-5.png";
import agency1 from "../../assets/images/home-page/we-invest.svg";
import agency2 from "../../assets/images/home-page/century-21.svg";
import agency3 from "../../assets/images/home-page/era.svg";
import agency4 from "../../assets/images/home-page/casteels.svg";
import agency5 from "../../assets/images/home-page/living-stone.svg";
import person1 from "../../assets/images/home-page/person-1.png";
import person2 from "../../assets/images/home-page/person-2.png";
import person3 from "../../assets/images/home-page/person-3.png";
import personUp from "../../assets/images/home-page/person-up.svg";

import Image from "next/image";
import { useTranslation } from "next-i18next";
import { ImQuotesRight } from "react-icons/im";
import CheckAvailabilitySection from "./CheckAvailabilitySection";
import { Button } from "antd";
import { BsArrowRight, BsClockFill } from "react-icons/bs";
import { useSwipeable } from "react-swipeable";

const ReviewCard = ({
	reviewNumber,
	reviewPerson,
	reviewAgency,
	reviewImage,
	isActive = false,
}) => {
	const { t } = useTranslation("home-page");

	return (
		<div className={`review-card ${isActive ? "card-active" : ""}`}>
			<ImQuotesRight className="quote" />
			<div className="review-text">
				<div className="mb-3">{t(`review-${reviewNumber}-para-1`)}</div>
				<div>{t(`review-${reviewNumber}-para-2`)}</div>
			</div>
			<div style={{ gap: 10 }} className="d-flex flex-row align-items-center">
				<div style={{ width: 44, height: 44, position: "relative" }}>
					<Image src={reviewImage} layout="fill" />
				</div>
				<div>
					<div className="review-person">{reviewPerson}</div>
					<div className="review-agency">{reviewAgency}</div>
				</div>
			</div>
		</div>
	);
};

const HomePageComponent = () => {
	const { t } = useTranslation("home-page");
	const checkAvailabilityRef = useRef(null);

	const handleScrollIntoView = () => {
		if (checkAvailabilityRef.current) {
			checkAvailabilityRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const handlers = useSwipeable({
		onSwiped: (eventData) =>
			eventData.dir === "Right"
				? activeReview === 1
					? setActiveReview(3)
					: setActiveReview(activeReview - 1)
				: eventData.dir === "Left"
					? activeReview === 3
						? setActiveReview(1)
						: setActiveReview(activeReview + 1)
					: () => { },
		// onSwipeStart,
	});
	const [activeReview, setActiveReview] = useState(1);
	return (
		<div className="main-container">
			<div className="cover-block d-flex flex-column justify-content-end">
				<Image style={{ zIndex: 1 }} src={cover} layout="fill" />
				<div
					style={{ gap: 50, zIndex: 1 }}
					className="w-100 d-flex flex-column align-items-center mb-4"
				>
					<div className="generate-more-sales-text">
						{t("generate-more-sales")}
					</div>
					<div className="our-team-text">{t("our-team-will-generate")}</div>
					<Button className="availability-button" size="large"
						onClick={handleScrollIntoView}>
						{t("check-availability")}
					</Button>
				</div>
				<div style={{ zIndex: 1 }} className="dashboard-image ">
					<Image
						className="d-block d-md-none"
						src={coverImageEnSm}
						layout="fill"
						objectFit="cover"
					/>
					<Image
						className="d-none d-md-block d-lg-none"
						src={coverImageEnMd}
						layout="fill"
						objectFit="cover"
					/>
					<Image
						className="d-none d-lg-block"
						src={coverImageEnLg}
						layout="fill"
						objectFit="contain"
					/>
				</div>
			</div>
			<div className="main-wrapper">
				<div className="sections-block">
					<div className="sections-block-inner">
						<div className="section-1 section-layout">
							<div className="text-section">
								<div className="step-text">{t("step-1")}</div>
								<div className="heading-text">{t("step-1-heading")}</div>
								<div
									className="d-block d-md-none"
									style={{ height: 290, position: "relative", width: "100%" }}
								>
									<Image src={section1} layout="fill" objectFit="contain" />
								</div>
								<div className="description-text">
									{t("step-1-description")}
								</div>
							</div>
							<div className="image-section">
								<Image src={section1} layout="fill" objectFit="contain" />
							</div>
						</div>
						<div className="section-2 section-layout">
							<div className="text-section">
								<div className="step-text">{t("step-2")}</div>
								<div className="heading-text">{t("step-2-heading")}</div>
								<div
									className="d-block d-md-none"
									style={{ height: 290, position: "relative", width: "100%" }}
								>
									<Image src={section2} layout="fill" objectFit="contain" />
								</div>
								<div className="description-text">
									{t("step-2-description")}
								</div>
							</div>
							<div className="image-section">
								<Image src={section2} layout="fill" objectFit="contain" />
							</div>
						</div>
						<div className="section-3 section-layout">
							<div className="text-section">
								<div className="step-text">{t("step-3")}</div>
								<div className="heading-text">{t("step-3-heading")}</div>
								<div
									className="d-block d-md-none"
									style={{ height: 290, position: "relative", width: "100%" }}
								>
									<Image src={section3} layout="fill" objectFit="contain" />
								</div>
								<div className="description-text">
									{t("step-3-description")}
								</div>
							</div>
							<div className="image-section">
								<Image src={section3} layout="fill" objectFit="contain" />
							</div>
						</div>
						<div className="section-4 section-layout">
							<div className="text-section">
								<div className="step-text">{t("step-4")}</div>
								<div className="heading-text">{t("step-4-heading")}</div>
								<div
									className="d-block d-md-none"
									style={{ height: 290, position: "relative", width: "100%" }}
								>
									<Image src={section4} layout="fill" objectFit="contain" />
								</div>
								<div className="description-text">
									{t("step-4-description")}
								</div>
							</div>
							<div className="image-section">
								<Image src={section4} layout="fill" objectFit="contain" />
							</div>
						</div>
						<div className="section-5 section-layout">
							<div className="text-section">
								<div className="step-text">{t("step-5")}</div>
								<div className="heading-text">{t("step-5-heading")}</div>
								<div
									className="d-block d-md-none"
									style={{ height: 290, position: "relative", width: "100%" }}
								>
									<Image src={section5} layout="fill" objectFit="contain" />
								</div>
								<div className="description-text">
									{t("step-5-description")}
								</div>
							</div>
							<div className="image-section">
								<Image src={section5} layout="fill" objectFit="contain" />
							</div>
						</div>
					</div>
				</div>
				<div className="proven-way-block-outer">
					<div className="proven-way-block d-flex flex-column flex-xl-row align-items-center justify-content-between">
						<div className="proven-way-text">{t("the-proven-way")}</div>
						<div style={{ gap: 24 }} className="d-flex flex-column flex-md-row">
							<div className="orange-card">
								<div
									style={{
										padding: 10,
										background: "rgba(255,255,255,0.2)",
										width: "min-content",
										borderRadius: 8,
									}}
								>
									<BsClockFill style={{ fontSize: 25 }} />
								</div>
								<div className="heading-text">{t("never-waste-time")}</div>
								<div className="description-text">
									{t("our-team-will-directly-book")}
								</div>
								<div
									className="d-flex flex-row align-items-center"
									style={{ gap: 10, cursor: "pointer" }}
									onClick={handleScrollIntoView}
								>
									<div className="button-text">{t("try-it-now")}</div>
									<BsArrowRight style={{ fontSize: 20 }} />
								</div>
							</div>
							<div className="orange-card">
								<div
									style={{
										padding: 10,
										background: "rgba(255,255,255,0.2)",
										width: "min-content",
										borderRadius: 8,
									}}
								>
									<img src={personUp} />
								</div>
								<div className="heading-text">{t("we-offer-lead")}</div>
								<div className="description-text">
									{t("whether-you-supply")}
								</div>
								<div
									className="d-flex flex-row align-items-center"
									style={{ gap: 10, cursor: "pointer" }}
									onClick={handleScrollIntoView}
								>
									<div className="button-text">{t("try-it-now")}</div>
									<BsArrowRight style={{ fontSize: 20 }} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="firms-block d-flex flex-column">
					<div className="keep-the-trusted-text">{t("keep-the-trusted")}</div>
					<div className="d-flex flex-row flex-wrap justify-content-between mt-2 mt-md-4">
						<img src={agency1} />
						<img className="d-none d-md-block" src={agency2} />
						<img className="d-none d-md-block" src={agency3} />
						<img src={agency4} />
						<img src={agency5} />
						<img className="d-block d-md-none" src={agency2} />
						<img className="d-block d-md-none" src={agency3} />
					</div>
				</div>
			</div>
			<div className="d-flex flex-column its-clear-block">
				<div className="d-flex flex-row it-is-clear-text" style={{ gap: 10 }}>
					<div>{t("it-is-clear")}</div>
					{/* <div style={{ color: "#FC704C" }}></div>
					<div>Winleads</div> */}
				</div>
				<div className="dont-take-our-word mt-4">{t("dont-just-take")}</div>
				<div
					className="d-none d-lg-flex flex-row"
					style={{ gap: 24, marginTop: 50 }}
				>
					<ReviewCard
						reviewNumber={1}
						reviewPerson={"Sacha Bonroy"}
						reviewAgency={"C21 Zakenkantoor"}
						reviewImage={person1}
					/>
					<ReviewCard
						reviewNumber={2}
						reviewPerson={"William Vekemans"}
						reviewAgency={"Vyta"}
						reviewImage={person2}
					/>
					<ReviewCard
						reviewNumber={3}
						reviewPerson={"Hakima Saou"}
						reviewAgency={"C21 Capitale"}
						reviewImage={person3}
					/>
				</div>
				<div
					{...handlers}
					className="d-flex flex-column align-items-center d-lg-none mt-4"
				>
					{activeReview === 1 ? (
						<ReviewCard
							reviewNumber={1}
							reviewPerson={"Sacha Bonroy"}
							reviewAgency={"C21 Zakenkantoor"}
							reviewImage={person1}
							isActive={true}
						/>
					) : activeReview === 2 ? (
						<ReviewCard
							reviewNumber={2}
							reviewPerson={"William Vekemans"}
							reviewAgency={"Vyta"}
							reviewImage={person2}
							isActive={true}
						/>
					) : (
						<ReviewCard
							reviewNumber={3}
							reviewPerson={"Hakima Saou"}
							reviewAgency={"C21 Capitale"}
							reviewImage={person3}
							isActive={true}
						/>
					)}
					<div
						className="d-flex flex-row dots align-items-center"
						style={{ gap: 10 }}
					>
						<div
							onClick={() => setActiveReview(1)}
							className={`dot ${activeReview === 1 ? "active" : ""}`}
						/>
						<div
							onClick={() => setActiveReview(2)}
							className={`dot ${activeReview === 2 ? "active" : ""}`}
						/>
						<div
							onClick={() => setActiveReview(3)}
							className={`dot ${activeReview === 3 ? "active" : ""}`}
						/>
					</div>
				</div>
			</div>
			<CheckAvailabilitySection ref={checkAvailabilityRef} />
		</div>
	);
};

export default HomePageComponent;
