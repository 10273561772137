import axios from "axios";

export const getSellerLeads = (
	agency_id: string,
	page: number,
	limit: number = 10,
	q: string = ""
) => {
	return new Promise<any>(async (res, rej) => {
		try {
			const { data } = await axios.get(`/agency/seller-leads`, {
				params: {
					agency_id,
					page,
					limit,
					...(q !== "" && { q }),
				},
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const updateLeadStatus = (
	lead_id: string,
	status: string,
	lead: any,
	translated_status: string,
	locale: string,
	agency: any
) => {
	return new Promise<void>(async (res, rej) => {
		try {
			await axios.put(`/agency/seller-leads/${lead_id}`, {
				status,
				lead,
				translated_status,
				locale,
				agency,
			});
			res();
		} catch (error) {
			rej(error);
		}
	});
};

export const createCallback = (
	callback_date: string,
	callback_notes: string,
	lead: any,
	agency: any
) => {
	return new Promise<void>(async (res, rej) => {
		try {
			await axios.put(`/agency/seller-leads/${lead?.lead_id}`, {
				callback_date,
				callback_notes,
				lead,
				agency
			});
			res();
		} catch (error) {
			rej(error);
		}
	});
};

export const getStatusList = (agencyId: string) => {
	return new Promise<any[]>(async (res, rej) => {
		try {
			const { data } = await axios.get(`agency/appointment-status/${agencyId}`);
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const createNewStatus = (
	agency_id: string,
	status: string,
	icon_id: string
) => {
	return new Promise<any[]>(async (res, rej) => {
		try {
			const { data } = await axios.post(`agency/appointment-status`, {
				agency_id,
				status,
				icon_id,
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const updateNotes = (lead_id: string, notes: any[]) => {
	return new Promise<void>(async (res, rej) => {
		try {
			await axios.put(`/agency/seller-leads/${lead_id}`, {
				internal_notes: notes,
			});
			res();
		} catch (error) {
			rej(error);
		}
	});
};

export const qaNotify = (lead: any) => {
	return new Promise<void>(async (res, rej) => {
		try {
			await axios.post(`/agency/seller-leads/qa-notify`, {
				lead,
			});
			res();
		} catch (error) {
			rej(error);
		}
	});
};

export const agentRegister = (data: any) => {
	return new Promise<void>(async (res, rej) => {
		try {
			await axios.post(`/agency/winleads/homepage-form`, {
				data,
			});
			res();
		} catch (error) {
			rej(error);
		}
	});
};
