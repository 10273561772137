import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Button, Modal } from "react-bootstrap";
import { Dropdown, Menu, Input } from "antd";
import { Formik, Field, ErrorMessage, Form } from "formik";
import ErrorIcon from "../../../assets/images/error-icon.svg";
import PhoneNumberInput from "../../PhoneNumberInput";
import cover from "../../../assets/images/home-page/cover.png";
import { useLinkTranslation } from "../../../custom-hooks/useLinkTranslation";
import * as Yup from "yup";
import Image from "next/image";
import TickIcon from "../../../assets/images/tick-orange-icon.png";
import ArrowDown from "../../../assets/images/arrow-down-home-page.png";
import ArrowUp from "../../../assets/images/arrow-up-home-page.png";
import SuccessTickIcon from "../../../assets/images/winleads-home-tick.svg";
import { agentRegister } from "../../../network-requests/seller-leads";

const CheckAvailabilitySection = React.forwardRef<HTMLDivElement>((props, ref) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { t } = useTranslation("home-page");
	const { t: t2 } = useTranslation("steps");
	const { t: t4 } = useTranslation("common");
	const { t: t5 } = useTranslation("settings-page");
	const { RouterLink, routerPush } = useLinkTranslation();
	const router = useRouter();
	const { locale, query } = router;
	const payload = query?.payload;

	const [visible, setVisible] = useState(false);
	const sizeOptions = ["2-5", "6-10", "11-15", "16-25", "26-50", "50+"];
	const [selectedValue, setSelectedValue] = useState("");

	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.min(2, t5("error.minLength"))
			.max(50, t5("error.maxLength"))
			.required(t2("label.required")),
		postal_code: Yup.string()
			.min(2, t5("error.minLength"))
			.max(8, t5("error.maxLength"))
			.required(t2("label.required")),
		email: Yup.string()
			.email(t2("label.invalid-email"))
			.required(t2("label.required")),
		phone: Yup.string()
			.required(t2("label.required"))
			.matches(
				/((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/,
				t2("error.phone-notValid")
			),
		country: Yup.string().required(t2("label.required")),
		// size_of_agency_employees: Yup.string()
		// 	.required(t2("label.required")),
		t_c: Yup.boolean()
			.oneOf([true], t2("label.required"))
			.required(t2("label.required")),
	});

	const handleMenuClick = (key) => {
		setVisible(false);
		setSelectedValue(key);
	};

	const handleInputChange = (e) => {
		setVisible(false);
		setSelectedValue(e.target.value);
	};

	const handleDropdownVisibleChange = (flag) => {
		setVisible(flag);
	};

	const handleLogin = async (values, { setSubmitting }) => {
		values.size_of_agency_employees = selectedValue;
		try {
			setSubmitting(true);
			await agentRegister(values);
			setIsModalVisible(true);
		} catch (error) {
			console.error("Error submitting form:", error);
		} finally {
			setSubmitting(false);
		}
	};

	const handleCloseModal = () => {
		setIsModalVisible(false);
	};

	const handleSizeChange = (value) => {
		setSelectedValue(value);
	};

	const menu = (
		<Menu onClick={({ key }) => handleMenuClick(key)}>
			{sizeOptions.map((option, index) => (
				<Menu.Item
					key={option}
					onClick={() => {
						setVisible(false);
						handleSizeChange(option);
					}}
				>
					<div
						className="d-flex flex-row justify-content-between align-items-center"
						onClick={() => {
							setVisible(false);
							handleSizeChange(option);
						}}
					>
						<span
							style={{
								color: selectedValue === option ? "#FC704C" : "inherit",
							}}
						>
							{option} {t("employees")}
						</span>
						{selectedValue === option && (
							<img
								src={TickIcon}
								alt="tick"
								style={{ marginLeft: "auto", width: 12, height: 12 }}
							/>
						)}
					</div>
				</Menu.Item>
			))}
		</Menu>
	);

	return (
		<div className="check-availability-page" ref={ref}>
			<div className="cover-block d-flex flex-column align-items-center">
				<Image style={{ zIndex: 0 }} src={cover} layout="fill" />
				<div
					style={{ marginTop: 100 }}
					className="align-items-center d-flex flex-column col-12 col-md-8 col-lg-6 col-xl-5"
				>
					<h2 className="page-title">{t("check-availability-in")}</h2>
					<div className="page-subtitle">{t("act-fast")}</div>
					<Formik
						initialValues={{
							name: "",
							email: "",
							phone: "",
							country: "",
							postal_code: "",
							size_of_agency_employees: selectedValue,
							t_c: false,
						}}
						validationSchema={validationSchema}
						onSubmit={handleLogin}
					>
						{({
							isSubmitting,
							errors,
							touched,
							setFieldError,
							setFieldValue,
						}) => (
							<Form className="form-parent">
								<div className="d-flex flex-row justify-content-between form-input-block flex-wrap">
									<div className="col-md-6 col-sm-12 px-2 d-flex flex-column space-style">
										<label
											className="form-label"
											htmlFor="name"
											style={{ fontWeight: "bold" }}
										>
											{t2("label.name")} <span className="red-required">*</span>
										</label>
										<Field
											className="form-input"
											name="name"
											type="text"
											style={{
												border:
													errors.name && touched.name
														? "1px solid #EF4A1E"
														: "1px solid #CED5DE",
											}}
										/>
										<div className="d-flex flex-column align-items-start">
											{errors.name && touched.name && (
												<div className="d-flex">
													<img
														src={ErrorIcon}
														alt="ErrorIcon"
														style={{
															marginRight: "6px",
															height: "18px",
															marginTop: "2px",
														}}
													/>
													<ErrorMessage
														className="form-error"
														component="div"
														name="name"
													/>
												</div>
											)}
										</div>
									</div>
									<div className="col-md-6 col-sm-12 col-lg-6  px-2">
										<label
											className="form-label"
											htmlFor="email"
											style={{ fontWeight: "bold" }}
										>
											{t2("label.email")}{" "}
											<span className="red-required">*</span>
										</label>
										<div style={{ position: "relative" }}>
											<Field
												className="form-input form-email-style"
												name="email"
												type="email"
												style={{
													width: "100%",
													border:
														errors.email && touched.email
															? "1px solid #EF4A1E"
															: "1px solid #CED5DE",
												}}
											/>
										</div>
										<div className="d-flex flex-column align-items-start">
											{errors.email && touched.email && (
												<div className="d-flex">
													<img
														src={ErrorIcon}
														alt="ErrorIcon"
														style={{
															marginRight: "6px",
															height: "18px",
															marginTop: "2px",
														}}
													/>
													<ErrorMessage
														className="form-error"
														component="div"
														name="email"
													/>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="d-flex flex-row justify-content-between form-input-block">
									<div className="col-12 p-0 px-2">
										<label
											className="form-label"
											htmlFor="phone"
											style={{ fontWeight: "bold" }}
										>
											{t2("label.phone")}{" "}
											<span className="red-required">*</span>
										</label>
										{errors.phone && touched.phone ? (
											<PhoneNumberInput
												hasError={true}
												// placeholder={t("label.phone")}
												onChange={(value) => {
													setFieldValue("phone", value);
												}}
											/>
										) : (
											<PhoneNumberInput
												// placeholder={t("label.phone")}
												onChange={(value) => {
													setFieldValue("phone", value);
												}}
											/>
										)}
										<div className="d-flex flex-column align-items-start">
											{errors.phone && touched.phone && (
												<div className="d-flex">
													<img
														src={ErrorIcon}
														alt="ErrorIcon"
														style={{
															marginRight: "6px",
															height: "18px",
															marginTop: "2px",
														}}
													/>
													<ErrorMessage
														className="form-error"
														component="div"
														name="phone"
													/>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="d-flex flex-row justify-content-between form-input-block flex-wrap">
									<div className="col-md-6 col-sm-12  px-2 d-flex flex-column space-style">
										<label
											className="form-label"
											htmlFor="country"
											style={{ fontWeight: "bold" }}
										>
											{t("country")} <span className="red-required">*</span>
										</label>
										<Field
											className="form-input"
											name="country"
											type="text"
											style={{
												border:
													errors.country && touched.country
														? "1px solid #EF4A1E"
														: "1px solid #CED5DE",
											}}
										/>
										<div className="d-flex flex-column align-items-start">
											{errors.country && touched.country && (
												<div className="d-flex">
													<img
														src={ErrorIcon}
														alt="ErrorIcon"
														style={{
															marginRight: "6px",
															height: "18px",
															marginTop: "2px",
														}}
													/>
													<ErrorMessage
														className="form-error"
														component="div"
														name="country"
													/>
												</div>
											)}
										</div>
									</div>
									<div className="col-md-6 col-sm-12 col-lg-6  px-2 ">
										<label
											className="form-label"
											htmlFor="postal_code"
											style={{ fontWeight: "bold" }}
										>
											{t("postal-code")} <span className="red-required">*</span>
										</label>
										<div style={{ position: "relative" }}>
											<Field
												className="form-input form-email-style"
												name="postal_code"
												type="text"
												style={{
													width: "100%",
													border:
														errors.postal_code && touched.postal_code
															? "1px solid #EF4A1E"
															: "1px solid #CED5DE",
												}}
											/>
										</div>
										<div className="d-flex flex-column align-items-start">
											{errors.postal_code && touched.postal_code && (
												<div className="d-flex">
													<img
														src={ErrorIcon}
														alt="ErrorIcon"
														style={{
															marginRight: "6px",
															height: "18px",
															marginTop: "2px",
														}}
													/>
													<ErrorMessage
														className="form-error"
														component="div"
														name="postal_code"
													/>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="d-flex flex-row justify-content-between form-input-block">
									<div className="col-12 p-0 px-2">
										<label
											className="form-label"
											htmlFor="size_of_agency_employees"
											style={{ fontWeight: "bold" }}
										>
											{t("size-your-agency")}{" "}
											<span className="red-required">*</span>
										</label>
										<Dropdown
											overlay={menu}
											trigger={["click"]}
											open={visible}
											onOpenChange={handleDropdownVisibleChange}
										>
											<div
												style={{
													position: "relative",
												}}
											>
												<Input
													className="form-input"
													style={{
														width: "100%",
														border:
															errors.size_of_agency_employees &&
															touched.size_of_agency_employees
																? "1px solid #EF4A1E"
																: "1px solid #CED5DE",
													}}
													// placeholder="Select or Enter"
													value={selectedValue}
													onChange={handleInputChange}
												/>
												{visible ? (
													<img
														style={{ position: "absolute", top: 18, right: 10 }}
														src={ArrowUp}
														alt="Arrow Up"
														className="arrow-icon"
													/>
												) : (
													<img
														style={{ position: "absolute", top: 18, right: 10 }}
														src={ArrowDown}
														alt="Arrow Down"
														className="arrow-icon"
													/>
												)}
											</div>
										</Dropdown>
										<div className="d-flex flex-column align-items-start">
											{errors.size_of_agency_employees &&
												touched.size_of_agency_employees && (
													<div className="d-flex">
														<img
															src={ErrorIcon}
															alt="ErrorIcon"
															style={{
																marginRight: "6px",
																height: "18px",
																marginTop: "2px",
															}}
														/>
														<ErrorMessage
															className="form-error"
															component="div"
															name="size_of_agency_employees"
														/>
													</div>
												)}
										</div>
									</div>
								</div>
								<div className="mb-3 px-2">
									<div className="d-flex flex-row  align-items-center">
										<Field
											style={{
												border: "1px solid #AAB7C8",
												width: "14px",
												height: "14px",
											}}
											name="t_c"
											type="checkbox"
											className="mr-2"
										/>
										<label className="label-terms-condition">
											{t("i-agree-with")}
											<RouterLink href={"/privacy-policy"}>
												<a
													target="_blank"
													style={{ color: "#FC704C", marginLeft: "2px" }}
												>
													{t2("label.privacy")}
												</a>
											</RouterLink>
											<span className="red-required">*</span>
										</label>
									</div>
									<div style={{ marginTop: -10 }}>
										<div className="d-flex flex-column align-items-start mt-2">
											{errors.t_c && touched.t_c && (
												<div className="d-flex">
													<img
														src={ErrorIcon}
														alt="ErrorIcon"
														style={{
															marginRight: "6px",
															height: "18px",
															marginTop: "2px",
														}}
													/>
													<ErrorMessage
														className="form-error"
														component="div"
														name="t_c"
													/>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="d-flex flex-row justify-content-between form-input-block">
									<div className="col-12 p-0 px-2">
										<Button
											style={{
												fontWeight: "bold",
												background: "#FC704C",
												border: "none",
											}}
											className="form-button mt-2"
											block
											type="submit"
										>
											{isSubmitting
												? t2("button.loading")
												: t("check-availability")}
										</Button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
				<div
					style={{ zIndex: 1 }}
					className="short-footer d-flex justify-content-center"
				>
					<p>
						<span>© Winleads</span>{" "}
						<span className="p-cont">{new Date().getFullYear()}</span>
					</p>
					{/* <span style={{ color: "#FFFF" }}>•</span>
					<RouterLink href={"/terms-and-condition-agent"}>
						{t4("label.privacy")}
					</RouterLink> */}
					<span style={{ color: "#FFFF" }}>•</span>
					<RouterLink href={"/terms-and-conditions-agent"}>
						{t4("label.terms")}
					</RouterLink>
				</div>
				<Modal
					className="success-modals"
					show={isModalVisible}
					onHide={handleCloseModal}
					centered
				>
					<Modal.Header
						style={{
							borderBottom: "none",
							marginTop: "-10px",
							marginLeft: "-2px",
						}}
					>
						<Modal.Title
							className="mt-3"
							style={{ marginBottom: "0", marginTop: "-20px" }}
						>
							<div className="d-flex flex-column align-items-center justify-content-center">
								<img
									style={{ width: 80, height: 80, marginBottom: 20 }}
									src={SuccessTickIcon}
								/>
								<span className="success-modal-title">
									{t("thank-you-for-submitting")}
								</span>
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body style={{ marginTop: "-4px" }}>
						<div className="success-modal-1">
							<div className="text-style" style={{ fontWeight: "400" }}>
								{t("we-appreciate-your-interest")}
							</div>
							<div className="d-flex justify-content-center mt-4">
								<Button className="button-continue" onClick={handleCloseModal}>
									{t("okay")}
								</Button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		</div>
	);
});

export default CheckAvailabilitySection;
