import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React from "react";
import { useRouter } from "next/router";
import { faqs } from "../config/faqs";
import HeaderContainer from "../containers/Header";
import { SEOMapping } from "../config/seo";
import { useLinkTranslation } from "../custom-hooks/useLinkTranslation";
import HomePageComponent from "../components/HomePage";

const HomePage = () => {
	const { locale } = useRouter();
	const _faqs = faqs["estimate-home"][locale];
	const seo = SEOMapping["home"][locale];
	const { translateLink } = useLinkTranslation();

	return (
		<div style={{ height: "100%" }}>
			<HeaderContainer
				title={seo?.title}
				metaDescription={seo?.description}
				canonicalHref={translateLink(seo?.canonical)}
			/>

			<div
				className="d-flex flex-column justify-content-between"
				style={{
					minHeight: "calc( 100vh - var(--headerHeight) )",
				}}
			>
				<HomePageComponent />
				{/* <FooterContainer /> */}
			</div>
		</div>
	);
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
	const namespaces = [
		"header",
		"common",
		"footer",
		"home-page",
		"steps",
		"settings-page",
	];
	const _serverSideTranslations = await serverSideTranslations(
		locale,
		namespaces
	);

	return {
		props: {
			..._serverSideTranslations,
		},
	};
};

export default HomePage;
